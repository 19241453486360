import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

`;

export default Global;
