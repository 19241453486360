import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 2.4rem;
  height: 100%;
  max-width: 100%;
`;

export const Inner = styled.div`
  width: 128rem;
  max-width: 100%;
  margin: 0 auto;
`;

export const InnerSmall = styled.div`
  width: 120rem;
  max-width: 100%;
  margin: auto;
`;
export const InnerSmallest = styled.div`
  width: 110rem;
  max-width: 100%;
  margin: auto;
`;

export const PageWrapper = styled.div`
  height: calc(100vh - 10rem);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 2rem;
`;
