import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Container, InnerSmall } from './Elements';
import { colors, font } from '../consts/style';
import mq from '../style/mq';
import useTheme from '../hooks/useTheme';
import { footerTypes } from '../types/propTypes';

const Wrapper = styled.div`
  background: ${colors.darkBlue};
  padding: 6rem 0;

  ${InnerSmall} {
    display: flex;

    .menu-items {
      margin-left: 6rem;
      display: flex;
      flex-direction: column;
      ${font.menu};
      a {
        margin-bottom: 1.6rem;
        color: white;
        text-decoration: none;
        ${font.menu};
        &[aria-current='page'] {
          color: ${props => props.themeColor};
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    ${mq.tabletSmall`
      flex-wrap: wrap;
      .logo {
        width: 100%;
        margin-bottom: 2.4rem;
        img {
          max-width: 100%;
        }
      }
      .menu-items {
        margin-left: 0;
        a {
          font-size: 2rem;
        }
      }
    `}
  }
`;

export default function Footer({ location }) {
  const theme = useTheme(location.pathname);
  return (
    <Wrapper themeColor={theme && theme.color}>
      <Container>
        <InnerSmall>
          <div className="logo">
            {theme && <img loading="lazy" src={theme.logo} alt="Logo" />}
          </div>
          <div className="menu-items">
            <Link to="/">Home</Link>
            <Link to="/plug">Piercepoint TTB Plug</Link>
            <Link to="/centrifuge">Environ SRS Centrifuge</Link>
          
          </div>
        </InnerSmall>
      </Container>
    </Wrapper>
  );
}

Footer.propTypes = footerTypes;
