/* eslint-disable quotes */
import bp from './breakpoints';

const mq = {
  mobile: `${bp.mobile}px`,
  tabletSmall: `${bp.tabletSmall}px`,
  tablet: `${bp.tablet}px`,
  desktop: `${bp.desktop}px`,
};

export const bz = `
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  `;

export const colors = {
  dark: '#4F4F51',
  light: '#fff',
  blue: '#364257',
  teal: '#21B0B6',
  tealOpaque: '#21b0b6a8',
  black: '#000003',
  yellow: '#F2C847',
  darkBlue: '#2F3A4B',
  orange: '#D8851F',
  orangeOpaque: '#D8851F5C',
  green: '#2FAA78',
  greenOpaque: '#2FAA78a8',
};

export const font = {
  h1: `
        font-size: 3rem;
        font-family: "Oceania", sans-serif;r
        line-height: 100%;
        font-weight: 300;
    `,
  h1Post: `
        font-size: 3.6rem;
        font-family: "Avenir LT Std", sans-serif;
        line-height: 150%;
        @media screen and (max-width: ${mq.tablet}) {
            font-size: 2.8rem;
        }
    `,
  h2: `
        font-size: 3rem;
        font-family: "Avenir LT Std", sans-serif;
        line-height: 150%;
        @media screen and (max-width: ${mq.tablet}) {
            font-size: 2rem;
        }
    `,
  h3: `
        font-size: 3rem;
        font-family: "Avenir LT Std", sans-serif;
        line-height: 150%;
        @media screen and (max-width: ${mq.tablet}) {
            font-size: 2rem;
        }
    `,
  h3Post: `
        font-size: 2.4rem;
        font-family: "Avenir LT Std", sans-serif;
        line-height: 150%;
        @media screen and (max-width: ${mq.tablet}) {
            font-size: 2rem;
        }
    `,
  h4Post: `
        font-size: 2rem;
        font-family: "Avenir LT Std", sans-serif;
        line-height: 150%;
        @media screen and (max-width: ${mq.tablet}) {
            font-size: 1.6rem;
        }
    `,
  bodyHeading: `
        font-size: 1.6rem;
        font-family: "Avenir LT Std", sans-serif;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 150%;
    `,
  body: `
        font-size: 1.6rem;
        font-family: "Avenir LT Std", sans-serif;
        line-height: 150%;
    `,
  menu: `
        font-size: 2.6rem;
        font-family: "Avenir LT Std", sans-serif;
        line-height: 150%;
        font-weight: bold;
        text-transform: uppercase;

        @media screen and (max-width: ${mq.mobile}) {
          font-size: 1.8rem;
        }
    `,
  formInput: `
        font-size: 3.7rem;
        font-family: "Avenir LT Std", sans-serif;
        line-height: 150%;
        @media screen and (max-width: ${mq.tablet}) {
          font-size: 1.6rem;
        }
    `,
  small: `
        font-size: 1.4rem;
        font-family: "Avenir LT Std", sans-serif;
        line-height: 120%;
    `,
  button: `
    font-size: 1.6rem;
    font-family: "Oceania", sans-serif;
    line-height: 100%;
    font-weight: bold;
    text-tranform: uppercase;
    background: ${colors.teal};
    border: none;
    padding: 1.6rem 3.2rem;
    border-radius: .4rem;
    color: white;
    text-decoration: none;
    span {
      position: relative;
      top: 3px;
      font-weight: bold;

    }
    `,
  buttonCta: `
    font-size: 1.3rem;
    font-family: "Oceania", sans-serif;
    line-height: 100%;
    font-weight: bold;
    background: ${colors.orange};
    border: none;
    padding: 1.2rem 2.4rem;
    border-radius: .4rem;
    color: white;
    text-decoration: none;
    span {
      text-tranform: uppercase !important;
      position: relative;
      top: 2px;
      font-weight: bold;
    }`,
  buttonForm: `
    font-size: 1.8rem;
    font-family: "Avenir LT Std", sans-serif;
    line-height: 100%;
    font-weight: bold;
    background: ${colors.orange};
    border: .2rem solid white;
    padding: 1.2rem 2.4rem;
    border-radius: 100rem;
    color: white;
    text-decoration: none;
    span {
      text-tranform: uppercase !important;
      position: relative;
      top: 2px;
      font-weight: bold;
    }
    `,
};

export const boxShadow = {
  default: 'box-shadow: 0px 3px 6px #00000029;',
};

export const z = {
  header: `z-index: 50000;`,
  menu: `z-index: 75000;`,
  modalBackground: `z-index: 100000;`,
  modal: `z-index: 200000;`,
};

export const duration = 500;
