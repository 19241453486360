import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { colors, z, font, bz, boxShadow } from '../consts/style';
import { headerTypes } from '../types/propTypes';
import mq from '../style/mq';
import useTheme from '../hooks/useTheme';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  height: 5rem;
  ${mq.mobile`
  padding: 3rem;
  height: 4rem;
  `}
  ${z.header}
  img {
    width: 25.6rem;
    ${mq.tabletSmall`
      width: 18.7rem;
    `}
  }
  button {
    width: 4rem;
    height: 3rem;
    position: relative;
    background: transparent;
    border: none;
    padding: 0;
    ${mq.mobile`
    width: 3.2rem;
    height: 2rem;
    div {
      height: .4rem !important;
      &:nth-child(2n) {
        top: calc(50% - 0.2rem) !important;
      }
    }
    `}
    div {
      position: absolute;
      width: 100%;
      left: 0;
      height: 0.6rem;
      background: ${({ isPost }) => (isPost ? colors.darkBlue : 'white')};
      &:first-child {
        top: 0;
      }
      &:last-child {
        bottom: 0;
      }
      &:nth-child(2n) {
        top: calc(50% - 0.3rem);
      }
    }
  }
  ${mq.tabletSmall`
    padding: 2.8rem 2rem;
  `}
`;

const MobileMenu = styled.nav`
  position: fixed;
  right: 0;
  height: 100%;
  width: 49rem;
  max-width: 100%;
  padding: 2.4rem 4.8rem;
  background: ${colors.darkBlue};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  transform: translateX(100%);
  transition: 0.4s all;
  ${boxShadow.default};
  ${bz};
  ${z.menu};
  ${mq.tabletSmall`
    width: 100%;
    padding: 2rem;
  `}
  &.open {
    transform: translateX(0%);
  }
  .menu-heading {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      width: 3rem;
      height: 2rem;
      position: relative;
      background: transparent;
      border: none;
      padding: 0;
      div {
        position: absolute;
        width: 100%;
        left: 0;
        height: 0.6rem;
        background: white;
        top: calc(50% - 0.3rem);
        &:first-child {
          transform: rotate(45deg);
        }
        &:last-child {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .menu-items {
    margin-top: 8vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    img {
      margin-bottom: 2rem;
      width: 26rem;
      max-width: 100%;
    }
    a,
    button {
      text-align: left;
      border: 0;
      line-height: 100%;
      margin-bottom: 1.6rem;
      color: white;
      text-decoration: none;
      ${font.menu};
      &[aria-current='page'] {
        color: ${props => props.themeColor};
      }
    }
  }
`;

export default function Header({ location }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const theme = useTheme(location.pathname);
  useEffect(() => setMenuOpen(false), [location]);

  const isPost = location.pathname.includes('/post/');

  return (
    <Fragment>
      <Wrapper isPost={isPost}>
        <Link to="/">
          {theme && <img src={theme.logoDark || theme.logo} alt="Logo" />}
        </Link>
        <button
          aria-label="Open menu"
          aria-pressed={menuOpen}
          onClick={() => setMenuOpen(true)}
        >
          <div />
          <div />
          <div />
        </button>
      </Wrapper>
      <MobileMenu
        className={menuOpen && 'open'}
        themeColor={theme && theme.color}
      >
        <div className="menu-top">
          <div className="menu-heading">
            <button
              aria-label="Close menu"
              aria-pressed={!menuOpen}
              onClick={() => setMenuOpen(false)}
            >
              <div />
              <div />
            </button>
          </div>
          <div className="menu-items">
            {theme && <img src={theme.logo} alt="Logo" />}
            <Link to="/">Home</Link>
            <Link to="/plug">Piercepoint TTB Plug</Link>
            <Link to="/centrifuge">Environ SRS Centrifuge</Link>
           
          </div>
        </div>
      </MobileMenu>
    </Fragment>
  );
}

Header.propTypes = headerTypes;
