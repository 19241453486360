import React, { Fragment } from 'react';
import 'intersection-observer';

import Reboot from '../style/reboot';
import Global from '../style/global';
import { layoutTypes } from '../types/propTypes';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Layout = ({ location, children }) => {
  return (
    <Fragment>
      <Reboot />
      <Global />
      <Header location={location} />
      {children}
      <Footer location={location} />
    </Fragment>
  );
};

Layout.propTypes = layoutTypes;

export default Layout;
