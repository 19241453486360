import { useStaticQuery, graphql } from 'gatsby';
import { colors } from '../consts/style';

const themeQuery = graphql`
  {
    datoCmsMisc {
      orangeLogo {
        url
      }
      orangeLogoDark {
        url
      }
      greenLogo {
        url
      }
      tealLogo {
        url
      }
    }
  }
`;

export default function useTheme(pathname) {
  const data = useStaticQuery(themeQuery);
  const OrangeLogo = data.datoCmsMisc.orangeLogo.url;
  const OrangeLogoDark = data.datoCmsMisc.orangeLogoDark.url;
  const TealLogo = data.datoCmsMisc.tealLogo.url;
  const GreenLogo = data.datoCmsMisc.greenLogo.url;

  if (pathname.includes('/post/')) {
    return { logo: OrangeLogo, logoDark: OrangeLogoDark, color: colors.orange };
  }

  const themeMap = {
    '/': { logo: OrangeLogo, color: colors.orange },
    '/contact': { logo: OrangeLogo, color: colors.orange },
    '/contact/': { logo: OrangeLogo, color: colors.orange },
    '/plug': {
      logo: TealLogo,
      color: colors.teal,
      transparent: colors.tealOpaque,
    },
    '/plug/': {
      logo: TealLogo,
      color: colors.teal,
      transparent: colors.tealOpaque,
    },
    '/centrifuge': {
      logo: GreenLogo,
      color: colors.green,
      transparent: colors.greenOpaque,
    },
    '/centrifuge/': {
      logo: GreenLogo,
      color: colors.green,
      transparent: colors.greenOpaque,
    },
  };

  return themeMap[pathname];
}
