import PropTypes from 'prop-types';

export const seoTypes = {
  meta: PropTypes.object.isRequired,
};
export const headerTypes = {
  location: PropTypes.object.isRequired,
};

export const footerTypes = {
  location: PropTypes.object.isRequired,
};

export const layoutTypes = {
  children: PropTypes.node.isRequired,
};

export const heroTypes = {
  background: PropTypes.object.isRequired,
  backgroundMobile: PropTypes.object.isRequired,
  textImage: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
};

export const introTypes = {
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  introMp4: PropTypes.object.isRequired,
  introWebm: PropTypes.object.isRequired,
  introImage: PropTypes.object.isRequired,
  isSmall: PropTypes.bool.isRequired,
};

export const infoTypes = {
  blocks: PropTypes.array.isRequired,
  path: PropTypes.string,
};

export const diagramTypes = {
  diagramIcon: PropTypes.string.isRequired,
  diagramImage: PropTypes.object.isRequired,
  diagramPoints: PropTypes.array.isRequired,
  diagramInfoHeading1: PropTypes.string.isRequired,
  diagramInfoBody1: PropTypes.string.isRequired,
  diagramInfoHeading2: PropTypes.string.isRequired,
  diagramInfoBody2: PropTypes.string.isRequired,
  howToIcons: PropTypes.array,
  salesSheet: PropTypes.object.isRequired,
  vendors: PropTypes.array,
};

export const howToTypes = {
  howToTitle: PropTypes.string.isRequired,
  howToBody: PropTypes.string.isRequired,
  howToIcons: PropTypes.array.isRequired,
};

export const videoTypes = {
  videoId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
